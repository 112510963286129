import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-sub-item',
  templateUrl: './sub-item.component.html',
  styleUrls: ['./sub-item.component.scss'],
})
export class SubItemComponent implements OnInit {
  @Input() item: any;
  @Output() itemSelected = new EventEmitter<void>();

  isSelected = false;

  constructor(private router: Router) {}

  ngOnInit() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.checkActiveRoute();
      });

    this.checkActiveRoute();
  }

  selectItem() {
    this.isSelected = true;
    this.itemSelected.emit();
  }

  checkActiveRoute() {
    if (this.item.link) {
      this.isSelected = this.router.isActive(this.item.link, false);
    }
  }
}
