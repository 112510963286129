<div
  class="nav-item"
  [class.expanded]="isExpanded"
  [class.selected]="isSelected || isChildSelected"
>
  <div class="nav-link-wrapper">
    <a
      *ngIf="!item.children"
      [routerLink]="item.link"
      routerLinkActive="active-link"
      class="nav-link"
      [class.selected]="isSelected || isChildSelected"
      [ngClass]="{ 'tour-highlighted': tourService.isStepHighlighted(item.tourStepIndex) }"
      (click)="selectItem()"
    >
      <span class="material-icons nav-icon">{{ item.icon }}</span>
      <span class="nav-text">{{ item.name | translate }}</span>
    </a>
    <div
      *ngIf="item.children"
      class="nav-link expandable"
      (click)="toggleExpand()"
      [ngClass]="{ 'tour-highlighted': tourService.isStepHighlighted(item.tourStepIndex) }"
    >
      <div class="icon-badge-container">
        <span class="material-icons nav-icon">{{ item.icon }}</span>

        <span
          *ngIf="shouldShowPendingCount() && (notificationService.$pendingRequestsNo | async)"
          class="pending-count-badge"
        >
          {{ notificationService.$pendingRequestsNo | async }}
        </span>
      </div>
      <span class="nav-text">{{ item.name | translate }}</span>

      <span class="material-icons expand-icon">{{
        isExpanded ? 'expand_less' : 'expand_more'
      }}</span>
    </div>
  </div>
  <ul *ngIf="item.children && isExpanded" class="child-items">
    <app-sub-item
      *ngFor="let child of item.children | navigationPipe: loggedInUserClaims"
      [item]="child"
      (itemSelected)="handleItemSelected()"
    ></app-sub-item>
  </ul>
</div>
