<nav class="sidenav">
  <ul class="sidenav-list">
    <app-nav-item
      [loggedInUserClaims]="loggedInUserClaims"
      *ngFor="let item of navMenuItems | navigationPipe: loggedInUserClaims"
      [item]="item"
      [level]="0"
    ></app-nav-item>
  </ul>

  <img class="logo-powerd-by" src="../../../assets/logo/logo-powerd-by.png" />
</nav>
