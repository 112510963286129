import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs';
import { BaseComponent } from '../../shared/components/base/base.component';
import { TourStep } from '../model/tour-steps.model';
import { TourService } from '../services/tour.service';

@Component({
  selector: 'app-tour',
  templateUrl: './tour-dialog.component.html',
  styleUrls: ['./tour-dialog.component.scss'],
})
export class TourDialogComponent extends BaseComponent implements OnInit {
  isTourShown$ = this.tourService.isTourShown$;

  currentStep?: TourStep;
  lastStepNo?: number;

  constructor(public tourService: TourService) {
    super();
  }

  ngOnInit(): void {
    this.tourService.currentStep$.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.currentStep = res;
    });

    this.tourService.allStepsNo$.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.lastStepNo = res;
    });
  }

  onSkip() {
    this.tourService.skipTour();
  }

  onNext() {
    this.tourService.nextStep();
  }
}
