import { Pipe, PipeTransform } from '@angular/core';
import { ClaimMenuItemEnum } from '../../features/user-management/enums/claim-module.enum';
import { NavItem } from '../model/nav-item.model';

@Pipe({
  name: 'navigationPipe',
})
export class NavigationPipe implements PipeTransform {
  transform(navMenuItems: NavItem[], loggedInUserClaimMenuItems: ClaimMenuItemEnum[]): NavItem[] {
    return navMenuItems.filter(
      (item) =>
        !item.claimMenuItems ||
        item.claimMenuItems.some((claim) => loggedInUserClaimMenuItems.includes(claim))
    );
  }
}
