<header class="header">
  <div class="header-content">
    <div class="header-left">
      <span class="material-icons">apps</span>
      <!-- Icon before Seamfix title -->
      <img [src]="logoUrl" alt="Logo" class="logo" />
    </div>
    <div class="header-right">
      <div class="header-item">
        <img class="header-icon" src="../../../assets/svgs/feedback-icon.svg" />
        <span>{{ 'feedback?' | translate }}</span>
      </div>
      <div class="header-item">
        <span>{{ 'needHelp?' | translate }}</span>
      </div>
      <div
        class="header-item language-select"
        (click)="isLanguageDropdownOpen = !isLanguageDropdownOpen"
      >
        <img class="header-icon" src="../../../assets/icons/translate.png" />
        <span>{{ selectedLanguage | translate }}</span>
        <img
          class="header-icon language-select-arrow"
          src="../../../assets/svgs/arrow-down-icon.svg"
        />

        <div class="language-select-options" *ngIf="isLanguageDropdownOpen">
          <div
            class="language-select-option"
            *ngFor="let option of availableLanguageOptions; let i = index"
            (click)="setLanguage(option.value)"
          >
            <span class="language-select-options-label">{{ option.label | translate }}</span>
          </div>
        </div>
      </div>

      <div class="header-item logout" (click)="logOut()">
        <img class="header-icon" src="../../../assets/svgs/logout-icon.svg" />
        <span>{{ 'logout' | translate }}</span>
      </div>
    </div>
  </div>
</header>
