import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SharedModule } from '../shared/shared.module';
import { HeaderComponent } from './header/header.component';
import { LoadingComponent } from './loading/loading.component';
import { NavigationPipe } from './pipes/naviagion.pipe';
import { NavItemComponent } from './sidenav/nav-item/nav-item.component';
import { SubItemComponent } from './sidenav/nav-item/sub-item/sub-item.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { TourDialogComponent } from './tour/tour-dialog.component';

@NgModule({
  declarations: [
    HeaderComponent,
    SidenavComponent,
    NavItemComponent,
    SubItemComponent,
    NavigationPipe,
    LoadingComponent,
    TourDialogComponent,
  ],
  imports: [
    CommonModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    SharedModule,
  ],
  exports: [HeaderComponent, SidenavComponent, LoadingComponent, TourDialogComponent],
})
export class CoreModule {}
