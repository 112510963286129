import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '../../features/auth/services/auth.service';
import { LoggedInUserService } from '../../features/auth/services/logged-in-user.service';
import { BaseComponent } from '../../shared/components/base/base.component';
import { DialogComponent } from '../../shared/components/dialog/dialog.component';
import { SelectOption } from '../../shared/components/forms/models/option.model';
import { DEFAULT_LOGO_URL } from '../../shared/consts/defaults.const';
import { LocalStorageService } from '../../shared/services/local-storage.service';

export const AVAILABLE_LANGUAGES = ['en', 'fr'];

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent extends BaseComponent implements BaseComponent {
  logoUrl: string = DEFAULT_LOGO_URL;
  isLanguageDropdownOpen: boolean = false;

  availableLanguageOptions: SelectOption<string>[] = [
    { value: 'en', label: 'en' },
    { value: 'fr', label: 'fr' },
  ];

  constructor(
    private authService: AuthService,
    private dialog: MatDialog,
    private localStorageService: LocalStorageService,
    private translateService: TranslateService,
    public loggedInUserService: LoggedInUserService
  ) {
    super();
  }

  ngOnInit(): void {
    this.getLoggedInUser();
  }

  setLanguage(language: string) {
    this.localStorageService.storeLanguage(language);
    this.translateService.use(language);
  }

  private getLoggedInUser() {
    this.loggedInUserService.$loggedInUser.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      if (user?.organization.organization_branding?.logo_icon_url) {
        this.logoUrl = user?.organization.organization_branding?.logo_icon_url;
      }
    });
  }

  logOut() {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '450px',
      data: {
        title: 'logout',
        textContent: 'areYouSureLogout',
        confirmBtn: 'logout',
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        if (result?.confirm) {
          this.authService.logOut();
        }
      });
  }

  get selectedLanguage() {
    return this.localStorageService.getLanguage();
  }
}
