<div class="tour-wrapper" *ngIf="(isTourShown$ | async) && currentStep">
  <div
    class="tour-content-wrapper"
    [ngClass]="{
      'pointer-bottom': !currentStep.pointerPosition || currentStep.pointerPosition === 'bottom',
      'pointer-top': currentStep.pointerPosition === 'top',
      'pointer-left': currentStep.pointerPosition === 'left',
      'pointer-right': currentStep.pointerPosition === 'right',
    }"
    [ngStyle]="{ top: currentStep.top, left: currentStep.left }"
  >
    <div class="tour-header">
      <span class="tour-title">{{ currentStep.title | translate }}</span>
      <span class="tour-description">{{ currentStep.description | translate }}</span>
    </div>

    <div class="tour-footer">
      <span class="steps">
        {{ currentStep.stepIndex + 1 }} {{ 'tour.of' | translate }} {{ lastStepNo }}</span
      >
      <app-button type="secondary" (clickEvent)="onSkip()">{{
        'tour.skip' | translate
      }}</app-button>
      <app-button type="primary" (clickEvent)="onNext()">{{
        (currentStep.stepIndex + 1 === lastStepNo ? 'done' : 'tour.next') | translate
      }}</app-button>
    </div>
  </div>
</div>
