import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ClaimMenuItemEnum } from '../../../features/user-management/enums/claim-module.enum';
import { NavItem } from '../../model/nav-item.model';
import { NotificationService } from '../../services/notification.service';
import { TourService } from '../../services/tour.service';

@Component({
  selector: 'app-nav-item',
  templateUrl: './nav-item.component.html',
  styleUrls: ['./nav-item.component.scss'],
})
export class NavItemComponent implements OnInit {
  @Input() item!: NavItem;
  @Input() level: number = 0;
  @Input() isChild: boolean = false;
  @Input() loggedInUserClaims: ClaimMenuItemEnum[] = [];
  @Output() itemSelected = new EventEmitter<void>();

  pendingApplicationRequestsCount: number = 0;

  isExpanded = false;
  isSelected = false;
  isChildSelected = false;
  hasChildren = false;

  constructor(
    private router: Router,
    public tourService: TourService,
    public notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.checkActiveRoute();
    });

    this.checkActiveRoute();
    if (this.item.children) {
      this.hasChildren = true;
    }
  }

  shouldShowPendingCount() {
    return this.item.claimMenuItems?.includes(ClaimMenuItemEnum.ADMIN_APPLICATIONS_ACCESS_REQUESTS);
  }

  toggleExpand() {
    this.isExpanded = !this.isExpanded;
  }

  selectItem() {
    this.isSelected = true;
    this.itemSelected.emit();
  }

  handleItemSelected() {
    this.isExpanded = true;
    this.isChildSelected = true;
    this.itemSelected.emit();
  }

  checkActiveRoute() {
    if (this.item.link) {
      this.isSelected = this.router.isActive(this.item.link, false);
    } else if (this.item.children) {
      this.isChildSelected = this.item.children.some((child: any) =>
        this.router.isActive(child.link, false)
      );
      this.isExpanded = this.isChildSelected;
    }
  }
}
